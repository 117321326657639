import React, { useEffect } from "react"
import { useFormikContext } from "formik"

import _ from "lodash"
import * as FieldTypes from "./fieldTypes"
import TypeCustom from "./fieldTypes/TypeCustom"

function TypeConditional(props) {
  const { field_conditional, fieldname } = props.field
  const { values, setFieldValue } = useFormikContext()
  const getConditionalFieldKey = () => {
    const keyList = Object.keys(field_conditional)
    return keyList[0]
  }
  const conditionalFieldKey = getConditionalFieldKey()
  const conditionalFieldValue = field_conditional[conditionalFieldKey]
  const displayField = _.reduce(
    conditionalFieldValue,
    (acc, option) => {
      return acc || _.includes(values[conditionalFieldKey], option)
    },
    false
  )
  useEffect(() => {
    if (!displayField) {
      setFieldValue(fieldname, "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayField])
  if (!displayField) return null
  return props.children
}

export default function FieldBuilder({ fields }) {
  const fieldsOrdered = _.orderBy(fields, "order")
  return _.map(fieldsOrdered, (field) => {
    const { type, fieldname, options, label } = field
    const description = field.description || ""
    const fieldConditional = field.field_conditional || null
    const newProps = {
      fieldname,
      label,
      description,
      options,
    }
    switch (type) {
      case "custom":
        if (!_.isEmpty(fieldConditional)) {
          return (
            <TypeConditional field={field} key={fieldname}>
              <TypeCustom {...newProps} key={fieldname} />
            </TypeConditional>
          )
        }
        return <TypeCustom {...newProps} key={fieldname} />
      case "text":
        if (!_.isEmpty(fieldConditional)) {
          return (
            <TypeConditional field={field} key={fieldname}>
              <FieldTypes.TypeText {...newProps} />
            </TypeConditional>
          )
        }
        return <FieldTypes.TypeText {...newProps} key={fieldname} />
      case "textarea":
        return <FieldTypes.TypeTextarea {...newProps} key={fieldname} />
      case "multi-selector":
        return <FieldTypes.TypeMultiSelect {...newProps} key={fieldname} />
      case "dropdown":
        return <FieldTypes.TypeDropdown {...newProps} key={fieldname} />
      case "radiobutton":
        return <FieldTypes.TypeRadioButton {...newProps} key={fieldname} />
      case "no-special-characters":
        if (!_.isEmpty(fieldConditional)) {
          return (
            <TypeConditional field={field} key={fieldname}>
              <FieldTypes.TypeNoSpecialCharacters
                {...newProps}
                key={fieldname}
              />
            </TypeConditional>
          )
        }
        return (
          <FieldTypes.TypeNoSpecialCharacters {...newProps} key={fieldname} />
        )
      default:
        return null
    }
  })
}
