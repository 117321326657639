import React from "react"
import { useNavigate, Link } from "react-router-dom"

import { getAuth } from "firebase/auth"

import logo from "../images/logo_starset_analytics.png"

import styles from "../styles/NavMain.module.css"

export default function MainNav() {
  const navigate = useNavigate()
  return (
    <nav className={`nav-main ${styles.nav}`}>
      <div className={styles.secondaryMenu}>
        <ul>
          <li>
            <span className="material-icons">account_circle</span>
            <span className="label">Profile</span>
          </li>
          <li>
            <button
              onClick={async () => {
                await getAuth().signOut()
                navigate("/")
              }}
            >
              <span className="material-icons">logout</span>
              Logout
            </button>
          </li>
        </ul>
      </div>
      <div className={styles.mainMenu}>
        <img src={logo} alt="logo" className={styles.logo} />
        <ul>
          <li>
            <Link to="/">Workbench</Link>
          </li>
          <li>
            <Link to="/carrier-directory/list">Carrier Directory</Link>
          </li>
          <li>
            <Link to="/index-file/list">Index Management</Link>
          </li>
          <li>
            <Link to="/data-order/list">Project Data Orders</Link>
          </li>
          <li>
            <Link to="/mrfs/list">MRF Inventory</Link>
          </li>
          <li>
            <Link to="#">Data Room</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
