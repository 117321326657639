const ENTITY = {
  CARRIER: "carrier_directory",
  MRF_ORDER: "mrf_orders",
  MRF: "mrfs",
  DATA_ORDER: "data_orders",
}

const DEF = {
  FORM: "getFormDefinition",
}

const ADD = {
  CARRIER: "createCarrier",
  MRF_ORDER: "createMrfOrder",
  DATA_ORDER: "createDataOrder",
}

const EDIT = {
  CARRIER: "updateCarrier",
  DATA_ORDER: "updateDataOrder",
}

const SET = {
  ADD_MRFS_TO_ORDER: "addMrfsToOrder",
  PLACE_ORDER: "placeOrder",
}

const GET = {
  CARRIER_LIST: "getCarrierDirectoryList",
  DATA_ORDER_SUMMARY: "getDataOrderSummary",
  PROJECT_LIST: "getProjectList",
  MRF_ORDER_INDEX_LIST: "getMrfOrderIndexList",
  SEARCH_CARRIER_PLAN_DIST_BY_MARKET_TYPE:
    "getCarrierPlanDistributionByMarketType",
  SEARCH_PLANS_BY_ATTRIBUTE: "searchPlansByAttribute",
  SEARCH_CARRIER_PLAN_DIST_BY_ATT: "getCarrierPlanDistributionByAttribute",
  CARRIER_DIST_BY_MARKET_TYPE: "getCarrierPlanDistributionByMarketType",
  CARRIER_DIST_BY_ATT: "getCarrierPlanDistributionByAttribute",
  GET_LOCATIONS_BY_TIC: "getInNetworkLocationsByTicUuid",
  GET_PLAN_NAMES_BY_LOCATION: "getPlanNamesByLocationsAndPlans",
  GET_PROCESS_SUMMARY: "getProcessesSummary",
}

export { ENTITY, DEF, ADD, EDIT, SET, GET }
