import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { SearchCtx } from "./context/searchCtx"

import FlowA from "./Flow-A"
import FlowB from "./Flow-B"

export default function IndexSearch() {
  const { type } = useParams()
  const [planList, setPlanList] = useState([])
  const [locationList, setLocationList] = useState([])

  const [dataOrderId, setDataOrderId] = useState(null)
  const [carrier, setCarrier] = useState(null)
  const [index, setIndex] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const dataOrderFromUrl = urlParams.get("order") || null
    const carrierFromUrl = urlParams.get("carrier") || null
    const indexFromUrl = urlParams.get("index") || null

    setDataOrderId(dataOrderFromUrl)
    setCarrier(carrierFromUrl)
    setIndex(indexFromUrl)
  }, [])

  const handleSearchFlow = () => {
    switch (type) {
      case "flow-a":
        return <FlowA />
      case "flow-b":
        return <FlowB />
      default:
        return null
    }
  }

  const ctxValue = {
    dataOrderId,
    carrier,
    setCarrier,
    index,
    setIndex,
    planList,
    setPlanList,
    locationList,
    setLocationList,
  }

  return (
    <SearchCtx.Provider value={ctxValue}>
      {handleSearchFlow()}
    </SearchCtx.Provider>
  )
}
