import React from "react"

//comps
import FormWrapper from "./FormWrapper"

export default function Add() {
  return (
    <div className="container">
      <h1>Add Index File</h1>
      <FormWrapper type="add" />
    </div>
  )
}
