import React, { useState } from "react"
import Header from "./Header"
import Footer from "./Footer"

import NavMain from "../nav/MainNav"

export default function Layout({ children }) {
  const [toogle, setToogle] = useState(true)
  const handleClassName = toogle ? "expand" : "collapse"
  return (
    <div className={`theme-wrapper ${handleClassName}`}>
      <Header />
      <NavMain setToogle={setToogle} toogle={toogle} />
      <main className="body-main">{children}</main>
      <Footer />
    </div>
  )
}
