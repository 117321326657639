import React from "react"

import styles from "../styles/Header.module.css"

export default function Header() {
  return (
    <header className={`header ${styles.header}`}>
      <div>
        <strong>Analyst: THS Data Team</strong>
      </div>
      <div>
        <ul>
          <li>
            <span className="material-icons">shopping_cart</span>
          </li>
          <li>
            <span className="material-icons">history</span>
          </li>
          <li>
            <span className="material-icons">hourglass_top</span>
          </li>
        </ul>
      </div>
      <div>Help</div>
    </header>
  )
}
