import React, { useEffect, useState, useContext } from "react"
import _ from "lodash"
import axios from "axios"

import { Dropdown } from "primereact/dropdown"

import cloudFunctionsURL from "../../../config/cloudFunctionsURL"
import { SearchCtx } from "../context/searchCtx"

// constants
import { GET } from "../../../config/constants"

import { LoaderSkeleton } from "../../../components/Loaders"

export default function Carrier() {
  const searchCtx = useContext(SearchCtx)
  const [fetching, setFetching] = useState(true)
  const [carrierList, setCarrierList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const url = `${cloudFunctionsURL()}/${GET.CARRIER_LIST}`
      const result = await axios.post(url, {
        type: "dropdown",
        attribute: "reporting_market_name",
      })
      setCarrierList(result.data.data)
      setFetching(false)
    }
    fetchData()
  }, [])

  if (fetching) return <LoaderSkeleton count={1} wrapper={false} />

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <label htmlFor="carrier">Carrier</label>
      <Dropdown
        id="carrier"
        options={carrierList}
        value={searchCtx.carrier}
        onChange={(e) => {
          searchCtx.setIndex(null)
          searchCtx.setCarrier(e.value)
        }}
      />
    </div>
  )
}
