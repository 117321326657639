import React from "react"
import _ from "lodash"
import { useParams, useNavigate } from "react-router-dom"

import useGetDoc from "../../hooks/useGetDoc"

//comps
import { LoaderSmall } from "../../components/Loaders"
import FormWrapper from "./FormWrapper"

export default function Edit() {
  const { docId } = useParams()
  const [loading, error, data] = useGetDoc("mrf_orders", docId)
  const handleData = _.reduce(
    data,
    (acc, value, key) => {
      if (key === "ref_carrier_directory") {
        return {
          ...acc,
          ref_carrier_directory: value.id,
        }
      }
      return {
        ...acc,
        [key]: value,
      }
    },
    {}
  )

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }
  if (loading) return <LoaderSmall />
  return (
    <div className="container">
      <h1>Edit Index File</h1>
      <FormWrapper type="edit" docValues={handleData} />
    </div>
  )
}
