import React, { useRef } from "react"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Toast } from "primereact/toast"

import { db } from "../../firebase"

import useSubscribeAllDocs from "../../hooks/useSubscribeAllDocs"
import handleStatusMessage from "../../helpers/handleStatusMessage"

export default function IndexFileList() {
  const withRef = {
    key: "ref_carrier_directory",
    value: "reporting_entity_name",
  }

  const [loading, error, data] = useSubscribeAllDocs(
    "mrf_orders",
    withRef,
    "index"
  )

  const navigate = useNavigate()
  const toast = useRef(null)

  const handleDelete = async (docId) => {
    try {
      await db
        .collection("mrf_orders")
        .doc(docId)
        .delete()
      toast.current.show({
        severity: "success",
        summary: "Document Deleted",
        detail: "The document was successfully deleted.",
        life: 3000,
      })
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Deleting Document",
        detail: "An error occurred while deleting the document.",
        life: 3000,
      })
    }
  }

  const handleData = _.reduce(
    data,
    (acc, row) => {
      if (row.mrf_type === "index") {
        const handleActions = (docId) => {
          const items = [
            {
              label: "Delete",
              icon: "pi pi-times",
              command: () => handleDelete(docId),
            },
          ]
          return (
            <SplitButton
              label="Edit"
              onClick={() => navigate(`/index-file/edit/${docId}`)}
              model={items}
            />
          )
        }
        const modDate = row.timestamp_updated
          ? row.timestamp_updated.toDate()
          : null
        return [
          ...acc,
          {
            ...row,
            status: handleStatusMessage(row.mrf_order_status),
            updated: DateTime.fromJSDate(modDate).toFormat("F"),
            actions: handleActions(row.id),
          },
        ]
      }
      return acc
    },
    []
  )

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  return (
    <div className="container">
      <h1>Index Management</h1>
      <Button
        onClick={() => navigate("/index-file/add")}
        style={{ marginBottom: 15 }}
      >
        Add Index File
      </Button>
      <DataTable value={handleData} responsiveLayout="scroll" loading={loading}>
        <Column
          field="ref_carrier_directory"
          header="Carrier Name"
          filter
          sortable
        />
        <Column
          field="mrf_order_name"
          header="Insurance Market Name"
          filter
          sortable
        />
        <Column field="mrf_order_alias" header="Index Data Table" />
        <Column field="status" header="Status" />
        <Column field="updated" header="Last Update" sortable />
        <Column field="actions" header="Actions" />
      </DataTable>
      <Toast ref={toast} />
    </div>
  )
}
