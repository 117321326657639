import React, { useEffect, useState, useContext } from "react"
import _ from "lodash"
import axios from "axios"
import { Dropdown } from "primereact/dropdown"

import { SearchCtx } from "../context/searchCtx"
import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

// constants
import { GET } from "../../../config/constants"

import { LoaderSkeleton } from "../../../components/Loaders"

export default function IndexFile() {
  const searchCtx = useContext(SearchCtx)

  const [fetching, setFetching] = useState(true)
  const [orderList, setOrderList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true)
      const url = `${cloudFunctionsURL()}/${GET.MRF_ORDER_INDEX_LIST}`
      try {
        const result = await axios.post(url, {
          type: "dropdown",
          carrier_for_order: searchCtx.carrier,
        })
        setOrderList(result.data.data)
        setFetching(false)
      } catch (error) {
        console.log(url, error)
      }
    }
    fetchData()
  }, [searchCtx.carrier])

  if (fetching) return <LoaderSkeleton count={1} wrapper={false} />

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <label htmlFor="index_select">Select Table of Contents</label>
      <Dropdown
        id="index_select"
        options={orderList}
        value={searchCtx.index}
        onChange={(e) => searchCtx.setIndex(e.value)}
      />
    </div>
  )
}
