import React from "react"
import _ from "lodash"

import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

// hooks
import useGetAllDocs from "../../hooks/useSubscribeAllDocs"

// PR
import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// CONST
import { ENTITY } from "../../config/constants"

// comps
import handleStatusMessage from "../../helpers/handleStatusMessage"
import { LoaderSmall } from "../../components/Loaders"

export default function MRFList() {
  const [loading, error, data] = useGetAllDocs(ENTITY.MRF)
  const navigate = useNavigate()

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  if (loading) {
    return <LoaderSmall />
  }

  const handleData = _.map(data, (row) => {
    const handleActions = (docId) => {
      const items = [
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => {},
        },
      ]
      return (
        <SplitButton
          label="Edit"
          onClick={() => navigate(`/mrf/edit/${docId}`)}
          model={items}
          disabled={row.mrf_index_file_status === "downloading"}
        />
      )
    }
    return {
      ...row,
      mrf_status: handleStatusMessage(row.mrf_status),
      timestamp_updated: DateTime.fromJSDate(
        row.timestamp_updated.toDate()
      ).toFormat("F"),
      actions: handleActions(row.id),
    }
  })

  return (
    <div className="container">
      <h1>MRF Inventory</h1>
      <DataTable value={handleData} responsiveLayout="scroll">
        <Column field="id" header="Id" />
        <Column field="mrf_type" header="Type" sortable />
        <Column field="mrf_status" header="MRF Status" />
        <Column field="timestamp_updated" header="Updated Date" sortable />
        {/* <Column field="actions" header="Actions" /> */}
      </DataTable>
    </div>
  )
}
