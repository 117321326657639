import React from "react"
import { Message } from "primereact/message"

export default function handleStatusMessage(status) {
  switch (status) {
    case "PENDING":
      return <Message severity="warn" text="Pending" />
    case "OPEN":
      return <Message severity="info" text="Open to Edit" />
    case "WAITING_IN_CART":
      return <Message severity="info" text="Waiting in Cart" />
    case "ON_HOLD":
      return <Message severity="warn" text="On hold" />
    case "DOWNLOADING":
      return <Message severity="warn" text="Downloading" />
    case "DOWNLOAD":
      return <Message severity="info" text="Download" />
    case "DOWNLOADED":
      return <Message severity="success" text="Downloaded" />
    case "DONE":
      return <Message severity="success" text="Done" />
    case "PROCESS":
      return <Message severity="info" text="Process" />
    case "PROCESSING":
      return <Message severity="warn" text="Processing" />
    case "PRE_PROCESS":
      return <Message severity="info" text="Pre-Process" />
    case "PROCESSED":
      return <Message severity="success" text="Processed" />
    case "EXCLUDED":
      return <Message severity="error" text="Excluded" />
    case "ERROR":
      return <Message severity="error" text="Error" />
  }
}
