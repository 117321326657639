import React, { useState, useRef } from "react"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Toast } from "primereact/toast"

import { db } from "../../firebase"

import useSubscribeAllDocs from "../../hooks/useSubscribeAllDocs"
import handleStatusMessage from "../../helpers/handleStatusMessage"

export default function DataOrderList() {
  const withRef = {
    key: "ref_data_project",
    value: "project_name",
  }
  const [loading, error, data] = useSubscribeAllDocs("data_orders", withRef)
  const navigate = useNavigate()
  const toast = useRef(null)

  const handleDelete = async (docId) => {
    try {
      await db
        .collection("data_orders")
        .doc(docId)
        .delete()
      toast.current.show({
        severity: "success",
        summary: "Document Deleted",
        detail: "The document was successfully deleted.",
        life: 3000,
      })
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Deleting Document",
        detail: "An error occurred while deleting the document.",
        life: 3000,
      })
    }
  }

  const handleData = _.map(data, (row) => {
    const handleActions = () => {
      const { id: docId, data_order_status: status } = row
      const items = [
        {
          label: "Find MRF to process",
          icon: "pi pi-search",
          command: () => navigate(`/data-order/edit/${docId}`),
        },
        {
          label: "Add MRF to process",
          icon: "pi pi-plus",
          command: () => navigate(`/data-order/edit/${docId}?screen=3`),
        },
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => handleDelete(docId),
        },
      ]
      return (
        <SplitButton
          label="View Project"
          onClick={() => navigate(`/data-order/details/${docId}/${status}`)}
          model={items}
        />
      )
    }

    const modDate = row.timestamp_updated
      ? row.timestamp_updated.toDate()
      : null

    return {
      ...row,
      data_order_status: handleStatusMessage(row.data_order_status),
      updated: DateTime.fromJSDate(modDate).toFormat("F"),
      actions: handleActions(),
    }
  })

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }

  return (
    <div className="container">
      <h1>Project Data Orders</h1>
      <Button
        onClick={() => navigate("/data-order/add")}
        style={{ marginBottom: 15 }}
      >
        Create New Data Order
      </Button>
      <DataTable value={handleData} responsiveLayout="scroll" loading={loading}>
        <Column field="ref_data_project" header="Project" />
        <Column field="data_order_name" header="Data Order Name" />
        <Column field="data_order_status" header="Status" />
        <Column field="updated" header="Last Update" />
        <Column field="actions" header="Actions" />
      </DataTable>
      <Toast ref={toast} />
    </div>
  )
}
