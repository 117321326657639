import _ from "lodash"
import * as Yup from "yup"

export default function schemaBuilder(fields) {
  const initialValues = _.reduce(
    fields,
    (acc, field) => {
      const skipFieldTypes = ["control"]

      if (_.includes(skipFieldTypes, field.type)) return acc

      const defaultOption = field.defaultOption || null

      switch (field.type) {
        case "multi-selector":
          return {
            ...acc,
            [field.fieldname]: defaultOption || [],
          }
        default:
          return {
            ...acc,
            [field.fieldname]: defaultOption || "",
          }
      }
    },
    {}
  )
  const handleValidation = _.reduce(
    fields,
    (acc, field) => {
      const skipTypes = ["control"]

      if (_.includes(skipTypes, field.type)) return acc

      const [isRequiered, msg] = field.validation
      if (!isRequiered) return acc

      const isConditional = !_.isEmpty(field.field_conditional)

      if (isConditional) {
        const fieldConditional = field.field_conditional

        switch (field.type) {
          // TODO: clean cond......
          case "text":
            return {
              ...acc,
              [field.fieldname]: Yup.string().when("project_alias", {
                is: (value) => value === "asd",
                then: () => Yup.string().required(msg),
              }),
            }
          default:
            return acc
        }
      }

      switch (field.type) {
        case "text":
          return {
            ...acc,
            [field.fieldname]: Yup.string().required(msg),
          }
        case "number":
          return {
            ...acc,
            [field.fieldname]: Yup.number().required(msg),
          }
        case "multi-selector":
          return {
            ...acc,
            [field.fieldname]: Yup.array().required(msg),
          }
        case "dropdown":
          return {
            ...acc,
            [field.fieldname]: Yup.string().required(msg),
          }
        default:
          return {
            ...acc,
            [field.fieldname]: Yup.string().required(msg),
          }
      }
    },
    {}
  )
  const handleValidationSchema = () => Yup.object().shape(handleValidation)
  return [initialValues, handleValidationSchema()]
}
