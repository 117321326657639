import { useState, useEffect } from "react"
import { db } from "../firebase"
import _ from "lodash"

export default function useSubscribeAllDocs(collection, withRef = {}) {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    const unsubscribe = db
      .collection(collection)
      .orderBy("timestamp_updated", "desc")
      .onSnapshot(async (querySnapshot) => {
        let docList = []
        if (_.isEmpty(withRef)) {
          querySnapshot.forEach((doc) => {
            docList.push({
              id: doc.id,
              ...doc.data(),
            })
          })
        } else {
          for (const doc of querySnapshot.docs) {
            const docData = doc.data()
            const referenceField = docData[withRef.key]
            let referenceData = []
            try {
              const referenceDoc = await referenceField.get()
              referenceData = referenceDoc.data()
              docList.push({
                id: doc.id,
                ...doc.data(),
                [withRef.key]: referenceData[withRef.value],
              })
            } catch (error) {
              docList.push({
                id: doc.id,
                ...doc.data(),
                [withRef.key]: referenceData,
              })
            }
          }
        }
        setData(docList)
        setLoading(false)
      })

    return () => {
      unsubscribe()
    }
  }, [])

  return [loading, error, data]
}
