import React, { useState } from "react"
import { Chips } from "primereact/chips"

import styles from "../../../styles/Form.module.css"

export default function ProductNameTerms(props) {
  const [value, setValue] = useState([])
  return (
    <div className={styles.field}>
      <label htmlFor={props.fieldname} className={styles.label}>
        {props.label}
      </label>
      <div className="card p-fluid">
        <Chips
          value={value}
          onChange={(e) => setValue(e.value)}
          separator=","
        />
      </div>
    </div>
  )
}
