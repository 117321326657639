import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import axios from "axios"
import _ from "lodash"

import { GET } from "../../../config/constants"
import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

import { SearchCtx } from "../context/searchCtx"

// Loaders
import { LoaderProgressBar } from "../../../components/Loaders"

// comps
import CarrierSelection from "../components/CarrierSelection"

// PR
import { InputText } from "primereact/inputtext"
import { RadioButton } from "primereact/radiobutton"
import { Button } from "primereact/button"

// Data Grid
import ReactDataGrid from "@inovua/reactdatagrid-enterprise"

// Filters
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter"
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter"

import "@inovua/reactdatagrid-enterprise/index.css"

export default function Screen1() {
  const navigate = useNavigate()
  const searchCtx = useContext(SearchCtx)
  const { mrfOrder, type, screen } = useParams()
  const [loading, setLoading] = useState(true)
  const [searching, setSearching] = useState(false)
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [defaultFilterValue, setDefaultFilterValue] = useState([])
  const [selected, setSelected] = useState({})
  // const [currentIndex, setCurrentIndex] = useState(null)
  const [searchType, setSearchType] = useState("")
  const [searchTerm, setSearchTerm] = useState("")

  const URL = `${cloudFunctionsURL()}/${GET.SEARCH_PLANS_BY_ATTRIBUTE}`
  const queryParams = {
    mrf_order: mrfOrder,
    search_term: searchTerm,
    attribute: searchType,
  }

  function storeUrlToSession() {
    function extractURLParams(url) {
      const startIndex = url.indexOf("/", url.indexOf("//") + 2)
      const endIndex = url.indexOf("?")
      const pathname = url.substring(
        startIndex + 1,
        endIndex !== -1 ? endIndex : url.length
      )
      const searchParams = endIndex !== -1 ? url.substring(endIndex + 1) : "" // Extract the search parameters
      return `${pathname}${searchParams ? `?${searchParams}` : ""}`
    }
    const currentUrl = window.location.href
    const result = extractURLParams(currentUrl)
    sessionStorage.setItem("search", result)
  }

  const handleSearch = async () => {
    setSearching(true)
    const response = await axios.post(URL, {
      ...queryParams,
      mrf_order: mrfOrder,
    })
    setData(response.data.data)
    setSearching(false)
  }

  useEffect(() => {
    storeUrlToSession()
    async function fetchData() {
      const response = await axios.post(URL, {
        ...queryParams,
        only_columns: true,
      })
      const handleColumns = _.map(response.data.columns, (column) => {
        if (!_.isEmpty(column.filterEditor)) {
          switch (column.filterEditor) {
            case "SelectFilter":
              column.filterEditor = SelectFilter
              return column
            case "NumberFilter":
              column.filterEditor = NumberFilter
              return column
            case "DateFilter":
              column.filterEditor = "" // not in use.
              return column
            default:
              return column
          }
        }
        return column
      })
      setColumns(handleColumns)
      setDefaultFilterValue(response.data.defaultFilterValue)
      setLoading(false)
    }
    fetchData()
  }, [])

  if (loading) return <LoaderProgressBar />

  const handleNext = () => {
    searchCtx.setPlanList(selected)
    const nextScreen = Number(screen) + 1
    return navigate(`/search-index/${type}/${mrfOrder}/${nextScreen}`)
  }

  return (
    <div>
      <h1>Query Index by Plan ID or Name</h1>
      <CarrierSelection />

      <div style={{ display: "flex", gap: 5, marginBottom: 10 }}>
        <div>
          <RadioButton
            inputId="plan-id"
            name="plan-id"
            value="plan_id"
            onChange={(e) => setSearchType(e.value)}
            checked={searchType === "plan_id"}
          />
          <label htmlFor="plan-id">Search by Plan Id</label>
        </div>
        <div>
          <RadioButton
            inputId="plan-name"
            name="plan-name"
            value="plan_name"
            onChange={(e) => setSearchType(e.value)}
            checked={searchType === "plan_name"}
          />
          <label htmlFor="plan-name">Search by Plan Name</label>
        </div>
      </div>

      <div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Enter your query"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </span>
      </div>

      <Button
        label="Search Index"
        onClick={() => handleSearch()}
        style={{ marginTop: 10 }}
        outlined
      />

      <ReactDataGrid
        idProperty="id"
        selected={selected}
        loading={searching}
        checkboxColumn
        pagination
        defaultFilterValue={defaultFilterValue}
        onSelectionChange={({ selected }) => setSelected(selected)}
        columns={columns}
        style={{ minHeight: "60vh", marginTop: 15 }}
        defaultSelected={false}
        dataSource={data}
        enableColumnAutosize
      />

      <Button
        label="View Files for Order"
        onClick={() => handleNext()}
        style={{ marginTop: 10 }}
      />
    </div>
  )
}
