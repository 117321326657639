import React, { useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import { auth } from "./firebase"

// Layout
import Layout from "./layout"

// Pages
import Home from "./pages/Home"
import Login from "./pages/Login"

import * as CarrierDirectory from "./pages/CarrierDirectory"
import * as IndexFile from "./pages/IndexFile"
import * as DataOrder from "./pages/DataOrders"
import * as MRF from "./pages/MRF"
import IndexSearch from "./pages/IndexSearch"
import IndexSearchSummary from "./pages/IndexSearch/Summary"

import * as Grid from "./pages/Grid"

function App() {
  const [user, setUser] = useState(null)
  useState(
    () =>
      auth.onAuthStateChanged(async (user) => {
        user ? setUser(user) : setUser(null)
      }),
    []
  )
  const HandleRoutes = () => {
    if (user) {
      // TODO: query this User for role and pass it thru cxt
      return (
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="carrier-directory/add"
              element={<CarrierDirectory.Add />}
            />
            <Route
              path="carrier-directory/list"
              element={<CarrierDirectory.List />}
            />
            <Route
              path="carrier-directory/edit/:docId"
              element={<CarrierDirectory.Edit />}
            />

            <Route path="index-file/add" element={<IndexFile.Add />} />
            <Route path="index-file/list" element={<IndexFile.List />} />
            <Route path="index-file/edit/:docId" element={<IndexFile.Edit />} />

            <Route path="data-order/add" element={<DataOrder.Add />} />
            <Route path="data-order/list" element={<DataOrder.List />} />
            <Route
              path="data-order/details/:docId/:status"
              element={<DataOrder.Details />}
            />
            <Route path="data-order/edit/:docId" element={<DataOrder.Edit />} />

            <Route path="mrfs/list" element={<MRF.List />} />

            <Route path="grid/:planId" element={<Grid.GridTester />} />

            <Route
              path="search-index/:type/:mrfOrder/:screen"
              element={<IndexSearch />}
            />
            <Route
              path="search-index/summary/:dataOrder"
              element={<IndexSearchSummary />}
            />
          </Routes>
        </Layout>
      )
    }
    return (
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    )
  }

  return (
    <Router>
      <HandleRoutes />
    </Router>
  )
}

export default App
