import React from "react"
import { Fieldset } from "primereact/fieldset"

import Carrier from "./Carrier"
import IndexFile from "./IndexFile"

export default function CarrierSelection() {
  return (
    <Fieldset
      legend="Carrier selection"
      style={{ marginBottom: 15 }}
      toggleable
    >
      <div style={{ display: "flex", gap: 30 }}>
        <Carrier />
        <IndexFile />
      </div>
    </Fieldset>
  )
}
