import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useFormikContext } from "formik"

import { useLocation } from "react-router-dom"

export default function MrfType(props) {
  const { fieldname, label } = props
  const { setFieldValue } = useFormikContext()
  const location = useLocation()
  const currentPath = location.pathname
  useEffect(() => {
    const isIndexFile = _.includes(currentPath, "index-file")
    if (isIndexFile) {
      setFieldValue(fieldname, "index")
    }
  }, [])
}
