import app from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import * as storage from "firebase/storage"

const firebaseConfigDev = {
  apiKey: "AIzaSyAKkCy4xfheNw5UDoPA5DgyRcry_0XddWY",
  authDomain: "ths-data-dev.firebaseapp.com",
  projectId: "ths-data-dev",
  storageBucket: "ths-data-dev.appspot.com",
  messagingSenderId: "972167967433",
  appId: "1:972167967433:web:7efb6b6443dd05595d1318",
}

const firebaseConfigProd = {
  apiKey: "AIzaSyBQnSIbFrJkGek9r5kqRVPYwP1es-vZNj0",
  authDomain: "ths-data-platform-346520.firebaseapp.com",
  projectId: "ths-data-platform-346520",
  storageBucket: "ths-data-platform-346520.appspot.com",
  messagingSenderId: "678426445606",
  appId: "1:678426445606:web:641c95ae34e33073dfe3e7",
}

const isProd = process.env.REACT_APP_STAGE === "prod"

let firebaseConfig = isProd ? firebaseConfigProd : firebaseConfigDev

app.initializeApp(firebaseConfig)

const db = app.firestore()
const auth = app.auth()

export { db, auth, storage, firebaseConfig }
