import React from "react"

import ProductCoverageAreaStates from "./ProductCoverageAreaStates"
import ProductCoverageAreaMsa from "./ProductCoverageAreaMsa"
import ProductNameTerms from "./ProductNameTerms"
import MrfType from "./MrfType"
import MrfDate from "./MrfDate"
import RefCarrierDirectory from "./RefCarrierDirectory"
import RefDataProject from "./RefDataProject"
import DataOrderFromExistingProject from "./DataOrderFromExistingProject"
import CarrierForOrder from "./CarrierForOrder"
import IndexFileForOrder from "./IndexFileForOrder"

export { ProductCoverageAreaStates }

export default function TypeCustom(props) {
  const { fieldname } = props
  switch (fieldname) {
    case "product_coverage_area_states":
      return <ProductCoverageAreaStates {...props} />
    case "product_coverage_area_msa":
      return <ProductCoverageAreaMsa {...props} />
    case "product_name_terms":
      return <ProductNameTerms {...props} />
    case "mrf_type":
      return <MrfType {...props} />
    case "mrf_date":
      return <MrfDate {...props} />
    case "ref_carrier_directory":
      return <RefCarrierDirectory {...props} />
    case "ref_data_project":
      return <RefDataProject {...props} />
    case "data_order_from_existing_project":
      return <DataOrderFromExistingProject {...props} />
    case "carrier_for_order":
      return <CarrierForOrder {...props} />
    case "index_file_for_order":
      return <IndexFileForOrder {...props} />
    default:
      return null
  }
}
