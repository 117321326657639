import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import { useParams } from "react-router-dom"

import axios from "axios"

import ReactDataGrid from "@inovua/reactdatagrid-enterprise"

// Filters
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter"
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter"
// import DateFilter from "@inovua/reactdatagrid-community/DateFilter"

import "@inovua/reactdatagrid-enterprise/index.css"

const DATASET_URL =
  "http://localhost:5000/ths-data-dev/us-central1/getInNetworkLocationsByPlanId"

const DATASET_URL2 =
  "http://localhost:5000/ths-data-dev/us-central1/searchPlansByAttribute"

export default function GridTest() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(true)
  const [columns, setColumns] = useState([])
  const [defaultFilterValue, setDefaultFilterValue] = useState([])
  const [selected, setSelected] = useState({})

  const { planId } = useParams()

  useEffect(() => {
    async function fetchData() {
      // test 1: Search In-Network locations by plan ID
      const params = {
        mrf_order: "aetna_query_test",
        plan_id: planId,
      }
      // test 2: Search Plan Table by plan ID
      const params2 = {
        mrf_order: "aetna_query_test",
        search_term: planId,
        attribute: "plan_id",
      }

      // const response = await axios.post(DATASET_URL, params)
      const response = await axios.post(DATASET_URL2, params2)

      const handleColumns = _.map(response.data.columns, (column) => {
        if (!_.isEmpty(column.filterEditor)) {
          switch (column.filterEditor) {
            case "SelectFilter":
              column.filterEditor = SelectFilter
              return column
            case "NumberFilter":
              column.filterEditor = NumberFilter
              return column
            case "DateFilter":
              column.filterEditor = "" // not in use.
              return column
            default:
              return column
          }
        }
        return column
      })

      setData(response.data.data)
      setColumns(handleColumns)
      setDefaultFilterValue(response.data.defaultFilterValue)
      setLoading(false)
    }
    fetchData()
  }, [])

  const gridStyle = { minHeight: "80vh" }

  if (loading) return <div>Loading...</div>

  return (
    <div>
      <ReactDataGrid
        idProperty="id"
        selected={selected}
        checkboxColumn
        pagination
        defaultFilterValue={defaultFilterValue}
        onSelectionChange={({ selected }) => setSelected(selected)}
        columns={columns}
        style={gridStyle}
        defaultSelected={false}
        dataSource={data}
      />
    </div>
  )
}
