import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useFormikContext } from "formik"

import axios from "axios"
import { Field } from "formik"
import { Dropdown } from "primereact/dropdown"

import cloudFunctionsURL from "../../../config/cloudFunctionsURL"

// constants
import { GET } from "../../../config/constants"

import styles from "../../../styles/Form.module.css"
import { LoaderSkeleton } from "../../Loaders"

export default function IndexFileForOrder(props) {
  const [fetching, setFetching] = useState(true)
  const { fieldname, label } = props
  const [carrierList, setCarrierList] = useState([])

  const { values } = useFormikContext()
  const carrierForOrder = values.carrier_for_order || null

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true)
      const url = `${cloudFunctionsURL()}/${GET.MRF_ORDER_INDEX_LIST}`
      const params = {
        type: "dropdown",
        entity_name: carrierForOrder,
      }
      const result = await axios.post(url, params)
      setCarrierList(result.data.data)
      setFetching(false)
    }
    carrierForOrder && fetchData()
  }, [carrierForOrder])

  if (!carrierForOrder) return null
  if (fetching) return <LoaderSkeleton count={1} />

  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            <Dropdown
              id={fieldname}
              options={carrierList}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
