import React from "react"

import { Skeleton } from "primereact/skeleton"

import { ProgressSpinner } from "primereact/progressspinner"
import { ProgressBar } from "primereact/progressbar"

import formStyles from "../styles/Form.module.css"
import styles from "../styles/Loader.module.css"

export function LoaderSmall() {
  return (
    <div className={styles.wrapperSm}>
      <ProgressSpinner
        style={{ width: "30px", height: "30px" }}
        strokeWidth="4"
        animationDuration=".2s"
      />
    </div>
  )
}

export function LoaderSkeleton({ count, wrapper = true }) {
  const skeletons = []
  for (let i = 0; i < count; i++) {
    if (wrapper) {
      skeletons.push(
        <div className={formStyles.field} key={i}>
          <label className={formStyles.label}>
            <Skeleton width="20rem" height="2rem"></Skeleton>
          </label>
          <Skeleton width="16rem" height="3rem"></Skeleton>
        </div>
      )
    } else {
      skeletons.push(
        <div key={i}>
          <label style={{ marginBottom: 5, display: "block" }}>
            <Skeleton width="10rem" height="1rem"></Skeleton>
          </label>
          <Skeleton width="8rem" height="3rem"></Skeleton>
        </div>
      )
    }
  }

  return <>{skeletons}</>
}

export function LoaderProgressBar() {
  return (
    <div>
      <ProgressBar mode="indeterminate" style={{ height: "3px" }}></ProgressBar>
    </div>
  )
}

export function LoaderMedium() {
  return (
    <div className={styles.wrapperMed}>
      <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth="4"
        animationDuration=".4s"
      />
    </div>
  )
}

export default function LoaderFullPage() {
  return (
    <div className={styles.wrapperFull}>
      <ProgressSpinner />
    </div>
  )
}
