import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"

import "./styles/global.css"

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

//overwrites
import "./styles/overwrites.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
