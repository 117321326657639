import React, { useState } from "react"
import _ from "lodash"
import { storage as firebaseStorage, firebaseConfig } from "../../firebase"

// PR
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { FileUpload } from "primereact/fileupload"
import { ProgressBar } from "primereact/progressbar"

export default function MarkerFileUpload(props) {
  const [submitting, setSubmitting] = useState(false)
  const [timestampPrefix] = useState(Date.now())
  const [progress, setProgress] = useState(0)
  const [fileUrl, setFileUrl] = useState(null)
  const [visible, setVisible] = useState(false)

  const {
    getStorage,
    ref,
    uploadBytesResumable,
    deleteObject,
    getDownloadURL,
  } = firebaseStorage

  const storage = getStorage()
  const FOLDER_STRUCT = `/data_order_files/${props.dataOrder}`

  const removeHandler = ({ file }) => {
    const currentFilename = `${timestampPrefix}_${file.name}`
    const fullFilePath = `${FOLDER_STRUCT}/${currentFilename}`
    const fileRef = ref(storage, fullFilePath)
    deleteObject(fileRef)
      .then(async () => {
        setProgress(0)
        setFileUrl(null)
      })
      .catch((error) => {
        console.log(error) // please handle ERR!!
      })
  }

  const customUploadHander = (e) => {
    const [file] = e.files
    const uniqueFilename = `${timestampPrefix}_${file.name}`
    const path = `${FOLDER_STRUCT}/${uniqueFilename}`
    const storageRef = ref(storage, path)
    const metadata = {
      contentType: "application/csv",
    }
    const uploadTask = uploadBytesResumable(storageRef, file, metadata)
    uploadTask.on(
      "state_changed",
      (snapshot) =>
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break
          case "storage/canceled":
            // User canceled the upload
            break
          default:
            console.log(error)
        }
      },
      () => {
        const { fullPath } = uploadTask.snapshot.metadata
        setFileUrl(fullPath)
      }
    )
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    const options = {
      sourceFilename: fileUrl,
      sourceBucketName: firebaseConfig.storageBucket,
    }
    return await props.handlePlaceOrder("pre-process", options)
  }

  return (
    <div>
      <Button
        label="Place Order to Pre-Process"
        icon="pi pi-external-link"
        onClick={() => setVisible(true)}
      />
      <Dialog
        header="Upload Markers"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <>
          <FileUpload
            customUpload
            uploadHandler={customUploadHander}
            onRemove={removeHandler}
            onClear={() => {
              setFileUrl(null)
              setProgress(0)
            }}
            accept="application/csv"
            maxFileSize={10000000} // 10Mb
            disabled={submitting}
          />
          <ProgressBar value={progress} style={{ margin: "10px 0" }} />
          {!_.isEmpty(fileUrl) && (
            <Button
              onClick={() => handleSubmit()}
              disabled={submitting || props.submitting}
            >
              Submit
            </Button>
          )}
        </>
      </Dialog>
    </div>
  )
}
