import { useState, useEffect } from "react"
import { db } from "../firebase"

export default function useGetDoc(collection, docId) {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  useEffect(() => {
    const ref = db.collection(collection).doc(docId)
    const fetch = async () => {
      try {
        const doc = await ref.get()
        if (!doc.exists) {
          setError("No such document!")
          return setLoading(false)
        }
        setData({
          id: docId,
          ...doc.data(),
        })
        setLoading(false)
      } catch (error) {
        setError("Error fetching.")
        setLoading(false)
      }
    }
    fetch()
  }, [])
  return [loading, error, data]
}
