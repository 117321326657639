import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import useGetDoc from "../../hooks/useGetDoc"

//comps
import { LoaderProgressBar } from "../../components/Loaders"
import FormWrapper from "./FormWrapper"

export default function Edit() {
  const [currentScreen, setCurrentScreen] = useState(2)

  const { docId } = useParams()
  const [loading, error, data] = useGetDoc("data_orders", docId)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const screenFromParams = urlParams.get("screen")
    screenFromParams && setCurrentScreen(Number(screenFromParams))
  }, [])

  if (error) {
    return (
      <div>
        <h2>Error when fetching.</h2>
      </div>
    )
  }
  if (loading) return <LoaderProgressBar />
  return (
    <div className="container">
      <h1>Select Index and Query type</h1>
      <FormWrapper
        type="edit"
        docValues={data}
        dataOrderId={docId}
        currentScreen={currentScreen}
      />
    </div>
  )
}
