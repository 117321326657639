import React from "react"
import _ from "lodash"
import { DateTime } from "luxon"

import { useNavigate } from "react-router-dom"

import { Button } from "primereact/button"
import { SplitButton } from "primereact/splitbutton"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import useSubscribeAllDocs from "../../../hooks/useSubscribeAllDocs"
import handleStatusMessage from "../../../helpers/handleStatusMessage"

export default function ProjectsAndDataOrders() {
  const withRef = {
    key: "ref_data_project",
    value: "project_name",
  }

  const [loading, error, data] = useSubscribeAllDocs("data_orders", withRef)

  const navigate = useNavigate()
  const lastThree = _.slice(data, 0, 3)

  const handleData = _.map(lastThree, (row) => {
    const handleActions = () => {
      const { id: docId, data_order_status: status } = row
      const items = [
        {
          label: "Update Data Order",
          icon: "pi pi-book",
          command: () => navigate(`/data-order/edit/${docId}`),
        },
      ]
      return (
        <SplitButton
          label="View Project"
          onClick={() => navigate(`/data-order/details/${docId}/${status}`)}
          model={items}
        />
      )
    }

    const modDate = row.timestamp_updated
      ? row.timestamp_updated.toDate()
      : null

    return {
      ...row,
      data_order_status: handleStatusMessage(row.data_order_status),
      updated: DateTime.fromJSDate(modDate).toFormat("F"),
      actions: handleActions(),
    }
  })

  return (
    <div>
      <h2 style={{ marginBottom: 5 }}>Projects and Data Orders</h2>
      <small style={{ display: "block", marginBottom: 30 }}>
        Showing last three entries.
      </small>
      <DataTable value={handleData} responsiveLayout="scroll" loading={loading}>
        <Column field="ref_data_project" header="Project" />
        <Column field="data_order_name" header="Data Order Name" />
        <Column field="data_order_status" header="Status" />
        <Column field="updated" header="Last Update" />
        <Column field="actions" header="Actions" style={{ width: 240 }} />
      </DataTable>
      <br />
      <Button
        onClick={() => navigate("/data-order/add")}
        style={{ marginRight: 15 }}
        className="btn-secondary"
      >
        Create New Data Order
      </Button>
      <Button onClick={() => navigate("/data-order/list")}>
        Manage Projects
      </Button>
    </div>
  )
}
