import React, { useEffect, useState } from "react"
import axios from "axios"
import _ from "lodash"

import { Formik, Form } from "formik"
import { useNavigate } from "react-router-dom"

import schemaBuilder from "../../components/schemaBuilder"

// constants
import { ENTITY, DEF, ADD, EDIT } from "../../config/constants"

// hooks
import useGetFormDef from "../../hooks/useGetFormDef"

// functions URL
import cloudFunctionsURL from "../../config/cloudFunctionsURL"

// comps
import FieldBuilder from "../../components/FieldBuilder"
import { LoaderSkeleton } from "../../components/Loaders"

// helper
import objectToUrlParams from "../../helpers/objectToUrlParams"

// PR
import { Button } from "primereact/button"

export default function FormWrapper(props) {
  const { type, docValues, dataOrderId = null } = props

  const navigate = useNavigate()
  const [currentDataOrderDocId, setCurrentDataOrderDocId] = useState(null)
  const [currentScreen, setCurrentScreen] = useState(props.currentScreen)

  const [msg, setMsg] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const params = {
    formId: ENTITY.DATA_ORDER,
    endpoint: DEF.FORM,
  }

  const [error, loading, def] = useGetFormDef(params)

  if (error) return <div>Err...</div>
  if (loading) return <LoaderSkeleton count={4} />

  const { fields: allFields } = def

  const handleFieldsPerScreen = _.reduce(
    allFields,
    (acc, field) => {
      if (field.screen === currentScreen) return [...acc, field]
      return acc
    },
    []
  )

  const [initialValues, validationSchema] = schemaBuilder(handleFieldsPerScreen)

  const handleFirstScreen = async (values) => {
    const url = `${cloudFunctionsURL()}/${ADD.DATA_ORDER}`
    try {
      const response = await axios.post(url, values)

      if (response.data.status === "success") {
        setCurrentDataOrderDocId(response.data.data.docId)
        const { data_order_type } = values
        switch (data_order_type) {
          case "search":
            setCurrentScreen(2)
          case "process":
            setCurrentScreen(3)
        }
      }

      return setSubmitting(false)
    } catch (error) {
      setMsg(error)
      setSubmitting(true)
    }
  }

  const handleSecondScreen = (values) => {
    const { index_file_for_order, search_type } = values
    const order = dataOrderId || currentDataOrderDocId
    const urlParamsObj = {
      order,
      carrier: values.carrier_for_order,
      index: values.index_file_for_order,
    }
    const paramsString = objectToUrlParams(urlParamsObj)
    switch (search_type) {
      case "flow-a":
        return navigate(
          `/search-index/${search_type}/${index_file_for_order}/1?${paramsString}`
        )
      case "flow-b":
        return navigate(
          `/search-index/${search_type}/${index_file_for_order}/1?${paramsString}`
        )
    }
  }

  const handleThirdScreen = (values) => {
    // TODO: Send values to endpoint
    navigate("/data-order/list")
  }

  const handleSubmit = async (values) => {
    setSubmitting(true)
    switch (currentScreen) {
      case 1:
        return await handleFirstScreen(values)
      case 2:
        return handleSecondScreen(values)
      case 3:
        return handleThirdScreen(values)
      default:
        return null
    }
  }

  const handleInitialValues = () => {
    switch (type) {
      case "add":
        return initialValues
      case "edit":
        return {
          ...initialValues,
          ...docValues,
        }
    }
  }

  const handleBtn = () => {
    switch (currentScreen) {
      case 1:
        return (
          <Button type="submit" disabled={submitting}>
            Create Data Order
          </Button>
        )
      case 2:
        return (
          <Button type="submit" disabled={submitting}>
            Start Index Query
          </Button>
        )
      case 3:
        return (
          <Button type="submit" disabled={submitting}>
            Process MRF
          </Button>
        )
      default:
        return null
    }
  }

  return (
    <Formik
      initialValues={handleInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form style={{ margin: "15px 30px" }}>
          <div>{msg}</div>
          <FieldBuilder fields={handleFieldsPerScreen} />
          {handleBtn()}
        </Form>
      )}
    </Formik>
  )
}
