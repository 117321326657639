import React from "react"
import { useParams } from "react-router-dom"

// comps
import Screen1 from "./Screen1"
import Screen2 from "./Screen2"
import Screen3 from "./Screen3"

export default function FlowB() {
  const { screen } = useParams()
  switch (Number(screen)) {
    case 1:
      return <Screen1 />
    case 2:
      return <Screen2 />
    case 3:
      return <Screen3 />
  }
}
