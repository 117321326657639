import React from "react"
import _ from "lodash"
import { Field } from "formik"
import { RadioButton } from "primereact/radiobutton"

import styles from "../../styles/Form.module.css"

export default function TypeRadioButton(props) {
  const { fieldname, label, options } = props
  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            {_.map(options, (option) => {
              return (
                <div key={option.value}>
                  <Field name={fieldname}>
                    {({ field, meta }) => {
                      return (
                        <>
                          <RadioButton
                            inputId={`${fieldname}_${option.value}`}
                            name={field.name}
                            value={option.value}
                            onChange={field.onChange}
                            checked={field.value === option.value}
                          />
                          <label
                            htmlFor={`${fieldname}_${option.value}`}
                            style={{ marginLeft: 5 }}
                          >
                            {option.label}
                          </label>
                        </>
                      )
                    }}
                  </Field>
                </div>
              )
            })}

            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
