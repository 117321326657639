import React from "react"
import { Field } from "formik"
import { MultiSelect } from "primereact/multiselect"

import styles from "../../styles/Form.module.css"

export default function TypeMultiSelect(props) {
  const { fieldname, label } = props

  const options = [
    { label: "Group Health Plan", value: "Group Health Plan" },
    { label: "Health Insurance Issuer", value: "Health Insurance Issuer" },
    { label: "Third Party Administrator", value: "Third Party Administrator" },
  ]

  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            <MultiSelect
              id={fieldname}
              options={options}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
