import React from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "primereact/button"

export default function BackButton() {
  const navigate = useNavigate()
  return (
    <Button
      icon="pi pi-backward"
      label="Back"
      size="small"
      onClick={() => navigate(-1)}
    />
  )
}
