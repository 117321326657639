import React from "react"
import { Field } from "formik"
import { Dropdown } from "primereact/dropdown"

import styles from "../../../styles/Form.module.css"

export default function ProductCoverageAreaMsa(props) {
  const { fieldname, label } = props
  const options = [{ label: "Test", value: "Test" }]
  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            <Dropdown
              id={fieldname}
              options={options}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />

            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
