import React from "react"
import { Formik, Form } from "formik"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { Button } from "primereact/button"
import "../../styles/Login.css"
import logo from "../../images/logo_starset_analytics_services.png"
import { auth } from "../../firebase"
import * as Yup from "yup"

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string().required("Required"),
})

export default function Login() {
  return (
    <div className="layout">
      <div className="session session-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="session session-form">
        <h1>Login</h1>
        <Formik
          className="login-form"
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values) => {
            const { username: email, password } = values
            await auth.signInWithEmailAndPassword(email, password)
          }}
        >
          {(formik) => {
            const { errors, touched, values, setFieldValue } = formik
            return (
              <Form style={{ margin: "15px 30px" }}>
                <span className="p-float-label">
                  <InputText
                    id="username"
                    name="username"
                    required
                    value={values.username}
                    onChange={({ target: { value } }) =>
                      setFieldValue("username", value)
                    }
                  />
                  <label>Username</label>
                  {touched.username && errors.username && (
                    <div style={{ color: "#ff678c" }}>{errors.username}</div>
                  )}
                </span>
                <span className="p-float-label">
                  <Password
                    inputId="password"
                    name="password"
                    value={values.password}
                    onChange={({ target: { value } }) =>
                      setFieldValue("password", value)
                    }
                  />
                  <label htmlFor="password">Password</label>
                </span>
                {touched.password && errors.password && (
                  <div style={{ color: "#ff678c" }}>{errors.password}</div>
                )}
                <Button type="submit">Login</Button>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
